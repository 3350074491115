import React from "react";

function Home() {
  return (
    <>
      <div className="container">
        <p>hello there</p>
      </div>
    </>
  );
}

export default Home;

import { collection, getDocs } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { db } from "../../Firebase";
import { setPerson } from "../../stores/reducers/personSlice";
import {
  Button,
  Box,
  Container,
  CssBaseline,
  Grid,
  Paper,
  LinearProgress,
  Divider,
} from "@mui/material";

function Persons() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [pesons, setPesons] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    getPesons();
  }, []);

  const getPesons = async () => {
    try {
      setLoading(true);
      const data = [];
      const querySnapshot = await getDocs(collection(db, "persones"));
      querySnapshot.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });
      setPesons(data); // fetch data from the firebase database
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const navigateTodetail = (id) => {
    const pesonsData = pesons.filter((item) => item.id === id);
    dispatch(setPerson(pesonsData));
    navigate(`/persondetails`);
  };

  return (
    <>
      <CssBaseline />
      <Container maxWidth="md">
        <Paper elevation={3}>
          <Box mt={2}>
            {loading ? <LinearProgress color="primary" /> : null}
            <Grid container padding={2}>
              <Grid md={4} xs={6}>
                <h4>Persons</h4>
              </Grid>
              <Grid md={4} xs={0}></Grid>
              <Grid md={4} xs={6} textAlign="right">
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => navigate("/addperson")}
                >
                  Ajouter
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Divider color="#2e7d32" />
          <div className="">
            <div className="body">
              <div
                class="list-group"
                style={{ borderRadius: 0, border: "none" }}
              >
                {pesons.map((person, index) => (
                  <div
                    onClick={() => navigateTodetail(person.id)}
                    key={index}
                    href="#"
                    class="list-group-item list-group-item-action"
                    style={{
                      borderBottom: "1px solid #c2c2c2",
                      borderTop: "none",
                      borderRight: "none",
                      borderLeft: "none",
                      alignItems: "center",
                    }}
                  >
                    <div class="d-flex w-100 justify-content-between">
                      <h5 class="mb-1"> {person.nom}</h5>
                    </div>
                    <p class="mb-1">{person.address}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Paper>
      </Container>
    </>
  );
}

export default Persons;

import React, { useEffect, useState } from "react";
import { arrayUnion, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import moment from "moment";
import { useSelector } from "react-redux";
import { db } from "../../../Firebase";
import "./payment.css";
import { Button, CircularProgress, IconButton } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import { Grid } from "@mui/joy";

function Payment({ id }) {
  const [loading, setLoading] = useState(false);
  const [payAblLoad, setPayAblLoad] = useState(false);
  const [employeeID, setEmployeeID] = useState("");
  const [payments, setPayments] = useState([]);
  const [payAmount, setPayAmount] = useState("");
  const [note, setNote] = useState("");
  const [selectedMonth, setSelectedMonth] = useState(
    moment(new Date()).format("MM")
  );
  const [totalWorkingHours, setTotalWorkingHours] = useState(0);
  const [totalPaid, setTotalPaid] = useState(0);

  const [editIndex, setEditIndex] = useState(null);

  const months = [
    { label: "Janvier", value: "01" },
    { label: "Février", value: "02" },
    { label: "Mars", value: "03" },
    { label: "Avril", value: "04" },
    { label: "Mai", value: "05" },
    { label: "Juin", value: "06" },
    { label: "Juillet", value: "07" },
    { label: "Août", value: "08" },
    { label: "septembre", value: "09" },
    { label: "Octobre", value: "10" },
    { label: "Novembre", value: "11" },
    { label: "Décembre", value: "12" },
  ];

  useEffect(() => {
    getData();
  }, [selectedMonth]);

  const getData = async () => {
    try {
      const value = id;
      if (value !== null) {
        setLoading(true);
        const docRef = doc(db, "workinghours", value);
        const docSnap = await getDoc(docRef);
        if (docSnap && docSnap.exists()) {
          const dataArray = docSnap.data().dataArray || [];
          console.log(dataArray);
          if (dataArray.length > 0) {
            const filterByMonth = dataArray.filter(
              (item) =>
                item.month === selectedMonth &&
                item.totalHours !== 0 &&
                item.totalHours !== ""
            );
            let sumHoras = 0;
            filterByMonth.forEach((item) => {
              sumHoras += hoursStringToDecimal(item.totalHours);
            });
            setTotalWorkingHours(decimalHoursToString(sumHoras));
            console.log("Document data:", decimalHoursToString(sumHoras));
          } else {
            setTotalWorkingHours(dataArray.totalHours);
          }
        } else {
          console.log("No such document!");
        }
        setEmployeeID(value);
        setLoading(false);
        getPayable(value);
        setTotalPaid(0);
      }
    } catch (e) {
      console.log(e);
    }
  };

  function hoursStringToDecimal(hoursString) {
    const [hoursPart, minutesPart] = hoursString.split(":");
    return Number(hoursPart) + Number(minutesPart) / 60;
  }

  function decimalHoursToString(hoursDecimal) {
    const numHours = Math.floor(hoursDecimal);
    const numMinutes = Math.round((hoursDecimal - numHours) * 60);
    return `${numHours < 10 ? "0" : ""}${numHours}:${
      numMinutes < 10 ? "0" : ""
    }${numMinutes}`;
  }

  const getPayable = async (employeeID) => {
    try {
      const docRef = doc(db, "payments", `${employeeID}-${selectedMonth}`);
      const docSnap = await getDoc(docRef);
      if (docSnap && docSnap.exists()) {
        const dataArray = docSnap.data() || [];
        setPayments(dataArray.payments);
        const payableData = dataArray.payments;
        const totalPayable = payableData.reduce((acc, item) => {
          return Number(acc) + Number(item.pay);
        }, 0);
        setTotalPaid(totalPayable);
      } else {
        setPayments([]);
        console.log("no payable added payable");
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handlePay = async (e) => {
    e.preventDefault();
    setPayAblLoad(true);
    const employeeID = id;
    try {
      const docRef = doc(db, "payments", `${employeeID}-${selectedMonth}`);
      const docSnap = await getDoc(docRef);
      if (docSnap && docSnap.exists()) {
        // const dataArray = docSnap.data().dataArray || [];
        const data = {
          pay: payAmount,
          note: note,
          date: new Date().toISOString(),
        };
        await updateDoc(docRef, {
          payments: arrayUnion(data),
        });
        console.log("payment done");
      } else {
        await setDoc(docRef, {
          payments: [
            {
              pay: payAmount,
              note: note,
              date: new Date().toISOString(),
            },
          ],
        });
      }
      setPayAmount("");
      getPayable(employeeID);
    } catch (e) {
      console.log(e);
    }
    setPayAblLoad(false);
  };

  const deleteHandl = async (index) => {
    if (index !== null) {
      const employeeID = id;
      try {
        setLoading(true);
        const docRef = doc(db, "payments", `${employeeID}-${selectedMonth}`);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const updatedData = { ...docSnap.data() };
          const newArray = [...updatedData.payments];
          newArray.splice(index, 1);
          await updateDoc(docRef, {
            payments: newArray,
          });
          getData();
        } else {
          console.log("No such document exists.");
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("id null");
    }
    setLoading(false);
  };

  const editHandl = (index) => {
    if (index !== null) {
      setEditIndex(index);
      setPayAmount(payments[index].pay);
      setNote(payments[index].note)
    } else {
      console.log("id null");
    }
  };
  const editCancel = () => {
    setEditIndex(null);
    setPayAmount("");
    setNote("")
  };

  const editFunction = async () => {
    if (editIndex !== null) {
      try {
        setPayAblLoad(true);
        const docRef = doc(db, "payments", `${employeeID}-${selectedMonth}`);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const updatedData = { ...docSnap.data() };
          const newArray = [...updatedData.payments];
          newArray[editIndex] = {
            ...newArray[editIndex],
            pay: payAmount,
            note: note
          };
          await updateDoc(docRef, {
            payments: newArray,
          });
          setEditIndex(null);
          setPayAmount("");
          setNote("")
          getData();
        } else {
          console.log("No such document exists.");
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("id null");
    }
    setPayAblLoad(false);
  };

  return (
    <div className="row">
      <div className="card" style={{ margin: 0, border: 0 }}>
        {loading ? <CircularProgress size={25} color={"primary"} /> : null}
        <div class="card-body">
          <form>
            <div className="row">
              <Grid container spacing={1} sx={{ flexGrow: 1 }}>
                <Grid xs={6} md={3}>
                <div className="form-group">
                  <label>Sélectionnez un mois</label>
                  <select
                    class="form-select"
                    aria-label="Default select example"
                    onChange={(e) => setSelectedMonth(e.target.value)}
                  >
                    {months.map((item, index) => (
                      <option
                        value={item.value}
                        selected={item.value === selectedMonth}
                        key={index}
                      >
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
                </Grid>
                <Grid xs={6} md={3}>
                <div className="form-group">
                  <label>Montante</label>
                  <input
                    type="number"
                    class="form-control"
                    value={payAmount}
                    onChange={(e) => setPayAmount(e.target.value)}
                  />
                </div>
                </Grid>
                <Grid xs={6} md={3}>
                <div className="form-group">
                  <label>Note</label>
                  <input
                    type="text"
                    class="form-control"
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                  />
                </div>
                </Grid>
                <Grid xs={6} md={3}>
                <div className="form-group flex-col">
                  <label>Total H : {totalWorkingHours}</label>
                  {editIndex !== null ? (
                    <div className="row">
                      <div className="col-2">
                        <IconButton color="error" onClick={editCancel}>
                          <CancelIcon size={15} color={"error"} />
                        </IconButton>
                      </div>
                      <div className="col-10">
                        <button
                          type="button"
                          class="btn btn-primary"
                          onClick={editFunction}
                        >
                          Mise à jour
                        </button>
                      </div>
                    </div>
                  ) : (
                    <button
                      type="button"
                      class="btn btn-primary"
                      onClick={handlePay}
                    >
                      {payAblLoad ? "Saving..." : "Payer"}
                    </button>
                  )}
                </div>
                </Grid>
              </Grid>
              

              <div className="col-md-4 col-sm-12">
                
              </div>
            </div>
          </form>
          <div className="paymens-data">
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div className="payments">
                  <h5>Paiements</h5>
                  <table class="table table-striped payEntry">
                    <thead>
                      <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Montante</th>
                        <th scope="col">Note</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {payments &&
                        payments.map((item, index) => (
                          <tr key={index}>
                            <td>{moment(item.date).format("DD-MM-YYYY")}</td>
                            <td>{item.pay}</td>
                            <td>{item.note}</td>
                            <td>
                              <Button
                                variant="text"
                                size="medium"
                                onClick={() => editHandl(index)}
                              >
                                <EditIcon size={15} color={"success"} />
                              </Button>
                              <Button
                                variant="text"
                                size="medium"
                                onClick={() => deleteHandl(index)}
                              >
                                <DeleteForeverIcon size={15} color={"error"} />
                              </Button>
                            </td>
                          </tr>
                        ))}
                      <tr>
                        <td style={{ color: "green" }}>Total</td>
                        <td style={{ color: "green" }}>
                          <b>{totalPaid}</b>
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Payment;

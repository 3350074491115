import { collection, doc, setDoc } from "firebase/firestore";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../Firebase";
import "./addChantier.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Paper, Divider } from "@mui/material";

function AddChantier() {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [client, setClient] = useState("");
  const [adress, setAdress] = useState("");
  const [mail, setMail] = useState("");
  const [tel, setTel] = useState("");
  const [details, setDetails] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [budget, setBudget] = useState("");
  const [projectManager, setProjectManager] = useState("");

  const navigate = useNavigate();

  const saveHandler = async (e) => {
    e.preventDefault();
    // save to the firebase database
    setLoading(true);
    try {
      const projectRef = doc(collection(db, "projects"));
      await setDoc(projectRef, {
        name,
        client,
        adress,
        mail,
        tel,
        details,
        startDate,
        endDate,
        budget,
        projectManager,
      });
      setLoading(false);
      navigate("/chantier");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="container">
      <Paper elevation={3} style={{ marginTop: 10, padding: 15 }}>
        <div className="row" style={{ padding: 10 }}>
          <div
            className="col-5"
            style={{ display: "flex", alignItems: "center" }}
          >
            <ArrowBackIcon
              size={20}
              color="primary"
              onClick={() => navigate("/chantier")}
            />
          </div>
        </div>
        <Divider color="#2e7d32" />
        <form className="add-employee" onSubmit={saveHandler}>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div class="mb-3">
                <label for="nom" class="form-label">
                  Nom du chantier
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="nom"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div class="mb-3">
                <label for="prenom" class="form-label">
                  Prénom et Nom de la Client
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="prenom"
                  value={client}
                  onChange={(e) => setClient(e.target.value)}
                />
              </div>
              <div class="mb-3">
                <label for="jobTitle" class="form-label">
                  Adress
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="jobTitle"
                  value={adress}
                  onChange={(e) => setAdress(e.target.value)}
                />
              </div>
              <div class="mb-3">
                <label for="tel" class="form-label">
                  Tel
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="tel"
                  value={tel}
                  onChange={(e) => setTel(e.target.value)}
                />
              </div>
              <div class="mb-3">
                <label for="mail" class="form-label">
                  Mail
                </label>
                <input
                  type="email"
                  class="form-control"
                  id="mail"
                  value={mail}
                  onChange={(e) => setMail(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div class="mb-3">
                <label class="form-label">Détails de Projet</label>
                <input
                  type="email"
                  class="form-control"
                  value={details}
                  onChange={(e) => setDetails(e.target.value)}
                />
              </div>
              <div class="mb-3">
                <label class="form-label">Date de Début</label>
                <input
                  type="date"
                  class="form-control"
                  value={details}
                  format="DD/MM/YYYY"
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
              <div class="mb-3">
                <label class="form-label">Date de Fin</label>
                <input
                  type="date"
                  class="form-control"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
              <div class="mb-3">
                <label class="form-label">Montant Total</label>
                <input
                  type="number"
                  class="form-control"
                  value={budget}
                  onChange={(e) => setBudget(e.target.value)}
                />
              </div>
              <div class="mb-3">
                <label class="form-label">Responsable de Chantier</label>
                <input
                  type="text"
                  class="form-control"
                  value={projectManager}
                  onChange={(e) => setProjectManager(e.target.value)}
                />
              </div>
            </div>
          </div>
          <button type="submit" class="btn btn-primary saveBtn">
            Sauvegarder
          </button>
        </form>
      </Paper>
    </div>
  );
}

export default AddChantier;

import { Button, LinearProgress } from "@mui/material";
import { arrayUnion, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../../Firebase";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { CircularProgress } from "@mui/material";
import "./note.css";

function Notes({ id }) {
  const [saveLoading, setSaveLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [note, setNote] = useState("");
  const [notes, setNotes] = useState([]);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    getNotes();
  }, []);

  const getNotes = async () => {
    if (id !== null) {
      try {
        setLoading(true);
        const docRef = doc(db, "notes", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setNotes(docSnap.data().notes);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const saveNoteHandler = async () => {
    if (id !== null) {
      try {
        setSaveLoading(true);
        const docRef = doc(db, "notes", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          await updateDoc(docRef, {
            notes: arrayUnion({ note, date: new Date().toDateString() }),
          });
        } else {
          await setDoc(docRef, {
            notes: [{ note, date: new Date().toDateString() }],
          });
        }
        getNotes();
        setNote("");
        setSaveLoading(false);
      } catch (error) {}
    }
  };

  const deleteHandl = async (index) => {
    if (index !== null) {
      try {
        setLoading(true);
        const docRef = doc(db, "notes", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const updatedData = { ...docSnap.data() };
          const newArray = [...updatedData.notes];
          newArray.splice(index, 1);
          await updateDoc(docRef, {
            notes: newArray,
          });
          getNotes();
          setNote("");
        } else {
          console.log("No such document exists.");
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("id null");
    }
    setLoading(false);
  };

  const editHandl = (index) => {
    console.log(index);
    if (index !== null) {
      setEditId(index);
      setNote(notes[index].note);
    }
  };
  const editCancel = () => {
    setEditId(null);
    setNote("");
  };

  const editFunction = async () => {
    if (editId !== null) {
      try {
        setSaveLoading(true);
        const docRef = doc(db, "notes", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const updatedData = { ...docSnap.data() };
          if (updatedData.notes && updatedData.notes.length > editId) {
            updatedData.notes[editId] = {
              ...updatedData.notes[editId],
              note: note,
            };
          } else {
            console.log("Array index out of range or array is empty.");
            return;
          } // this is for the case
          await updateDoc(docRef, updatedData);
          setNote("");
          setEditId(null);
          getNotes();
        } else {
          console.log("No such document exists.");
        }
        setSaveLoading(false);
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("id null");
    }
  };

  return (
    <div className="row">
      <div class="card-body" style={{padding: 10}}>
        <form className="notes-form">
          <div className="col-md-8 col-sm-12">
            <div className="form-group">
              <input
                class="form-control"
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-4 col-sm-12">
            {editId !== null ? (
              <>
                <Button variant="text" size="medium" onClick={editCancel}>
                  <CancelIcon size={15} color={"error"} />
                </Button>
                <button
                  type="button"
                  class="btn btn-primary"
                  onClick={editFunction}
                >
                  {saveLoading ? "Saving..." : "mise à jour"}
                </button>
              </>
            ) : (
              <div className="form-group">
                <Button
                  type="button"
                  style={{ marginLeft: "10px" }}
                  onClick={saveNoteHandler}
                  variant="contained"
                >
                  {saveLoading ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    "Sauvegarder"
                  )}
                </Button>
              </div>
            )}
          </div>
        </form>
        <div className="notes-data">
          <p>Remarques</p>
          <table class="table table-striped noteEntry">
            <tbody>
              {notes.map((item, index) => (
                <tr key={index}>
                  <td className="singleNote">{item.note}</td>
                  <td>
                    <Button
                      variant="text"
                      size="medium"
                      onClick={() => editHandl(index)}
                    >
                      <EditIcon size={15} color={"success"} />
                    </Button>
                    <Button
                      variant="text"
                      size="medium"
                      onClick={() => deleteHandl(index)}
                    >
                      <DeleteForeverIcon size={15} color={"error"} />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Notes;

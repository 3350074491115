// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, initializeAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

//Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCTmrpPROb_wf41wAglBcN4iyZCBnE_p5A",
  authDomain: "batapp-78f7d.firebaseapp.com",
  projectId: "batapp-78f7d",
  storageBucket: "batapp-78f7d.appspot.com",
  messagingSenderId: "346326790741",
  appId: "1:346326790741:web:82af240c6e3bd29ac5dadc",
};

// const firebaseConfig = {
//   apiKey: "AIzaSyCiMCu_Com_0Nv__EEodxgKJ9G3Fw16SQA",
//   authDomain: "bats-1e470.firebaseapp.com",
//   projectId: "bats-1e470",
//   storageBucket: "bats-1e470.appspot.com",
//   messagingSenderId: "618316177503",
//   appId: "1:618316177503:web:8f4b72220c56f86d54bbea",
//   measurementId: "G-JT0STJHRR0"
// };

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

export const db = getFirestore(app);
export const storage = getStorage(app);

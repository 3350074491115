import { collection, getDocs } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { db } from "../../Firebase";
import { setProject } from "../../stores/reducers/projectSlice";
import {
  Button,
  Box,
  Container,
  CssBaseline,
  Grid,
  Paper,
  LinearProgress,
  Divider,
} from "@mui/material";

function Chantiers() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    getProjects();
  }, []);

  const getProjects = async () => {
    try {
      setLoading(true);
      const data = [];
      const querySnapshot = await getDocs(collection(db, "projects"));
      querySnapshot.forEach((doc) => {
        data.push({ ...doc.data(), id: doc.id });
      });
      setProjects(data); // fetch data from the firebase database
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const navigateTodetail = (id) => {
    const projectsData = projects.filter((item) => item.id === id);
    dispatch(setProject(projectsData));
    navigate(`/chantierdetails`);
  };

  return (
    <>
      <CssBaseline />
      <Container maxWidth="md">
        <Paper elevation={3}>
          <Box mt={2}>
            {loading ? <LinearProgress color="primary" /> : null}
            <Grid container padding={2}>
              <Grid md={4} xs={6}>
                <h4>Chantiers</h4>
              </Grid>
              <Grid md={4} xs={0}></Grid>
              <Grid md={4} xs={6} textAlign="right">
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => navigate("/addchantier")}
                >
                  Ajouter
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Divider color="#2e7d32" />
          <div className="">
            {/* <div className="row header">
              <div className="col-6">
                <h4>Chantiers</h4>
              </div>
              <div className="col-6 tl">
                <button
                  type="button"
                  class="btn btn-primary"
                  onClick={() => navigate("/addchantier")}
                >
                  Ajouter
                </button>
              </div>
            </div> */}
            <div className="body">
              <div
                class="list-group"
                style={{ borderRadius: 0, border: "none" }}
              >
                {projects.map((project, index) => (
                  <div
                    onClick={() => navigateTodetail(project.id)}
                    key={index}
                    href="#"
                    class="list-group-item list-group-item-action"
                    style={{
                      borderBottom: "1px solid #c2c2c2",
                      borderTop: "none",
                      borderRight: "none",
                      borderLeft: "none",
                      alignItems: "center",
                    }}
                  >
                    <div class="d-flex w-100 justify-content-between">
                      <h5 class="mb-1"> {project.name}</h5>
                    </div>
                    <p class="mb-1">{project.adress}</p>
                    <small class="text-body-secondary">
                      Responsable {project.projectManager}
                    </small>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Paper>
      </Container>
    </>
  );
}

export default Chantiers;

import React, { useEffect, useState } from "react";
import { Box, Button, Grid, IconButton, Input, LinearProgress } from "@mui/joy";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {
  arrayUnion,
  doc,
  getDoc,
  orderBy,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { DataGrid } from "@mui/x-data-grid";
import { useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import EuroIcon from "@mui/icons-material/Euro";
import CancelIcon from "@mui/icons-material/Cancel";
import moment from "moment";
import { db } from "../../../Firebase";
import "./expencese.css";
import { v4 as uuidv4 } from 'uuid';

function Expencese() {
  const { id } = useSelector((state) => state.person.person[0]);
  const [editId, setEditId] = useState(null);
  const [saveLoading, setSaveLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addDate, setAddDate] = useState(null);
  const [amount, setAmount] = useState("");
  const [note, setNote] = useState("");
  const [expenceses, setExpenceses] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    getExpenses();
  }, []);

  const getExpenses = async () => {
    if (id !== null) {
      try {
        setLoading(true);
        const docRef = doc(db, "personExpenceses", id);
        const docSnap = await getDoc(docRef, orderBy("date"));
        if (docSnap.exists()) {
          const expenses = docSnap.data().expenses;
          const orderedExpenses = expenses.slice().sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            return dateB - dateA;
          });
          setExpenceses(orderedExpenses);
          let total = orderedExpenses.reduce(
            (acc, expense) => acc + parseInt(expense.amount),
            0
          );
          setTotal(total);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const savePaymentHandler = async (e) => {
    e.preventDefault();
    if (id !== null) {
      try {
        setSaveLoading(true);
        const docRef = doc(db, "personExpenceses", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          await updateDoc(docRef, {
            expenses: arrayUnion({
              id: uuidv4(), // Generate UUID as ID
              date: new Date(addDate).toDateString(),
              note,
              amount,
            }),
          });
        } else {
          await setDoc(docRef, {
            expenses: [
              {
                id: uuidv4(), // Generate UUID as ID
                date: new Date(addDate).toDateString(),
                note,
                amount,
              },
            ],
          });
        }
        getExpenses();
        setAmount("");
        setNote("");
        setAddDate(null);
      } catch (error) {
        console.log(error);
      }
      setSaveLoading(false);
    } else {
      console.log("id null");
    }
  };

  const deleteHandl = async (index) => {
    if (index !== null) {
      try {
        setLoading(true);
        const docRef = doc(db, "personExpenceses", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const updatedData = { ...docSnap.data() };
          const newArray = [...updatedData.expenses];
          // newArray.splice(index, 1);
          var lists = newArray.filter((x) => {
            return x.id != index;
          });
          await updateDoc(docRef, {
            expenses: lists,
          });
          getExpenses();
        } else {
          console.log("No such document exists.");
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("id null");
    }
    setLoading(false);
  };

  const editHandl = (itemId) => {
    if (itemId !== null) {
      const value = expenceses.find((item) => item.id === itemId);
      if (value !== null) {
        setEditId(value.id);
        setAddDate(dayjs(value.date));
        // setAddDate(new Date(expenceses[index].date));
        setNote(value.note);
        setAmount(value.amount);
      } else {
        alert("Item not found");
      }
    } else {
      alert("id null");
    }
  };

  const editCancel = (e) => {
    e.preventDefault();
    setEditId(null);
    setAddDate(null);
    setNote("");
    setAmount("");
  };

  const editFunction = async (e) => {
    e.preventDefault();
    if (editId !== null) {
      try {
        setSaveLoading(true);
        const docRef = doc(db, "personExpenceses", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const updatedData = { ...docSnap.data() };
          const editedItemIndex = updatedData.expenses.findIndex(item => item.id === editId);
          if (editedItemIndex !== null) { 
            updatedData.expenses[editedItemIndex] = {
              id: editId,
              date: new Date(addDate).toDateString(),
              note: note,
              amount: amount,
            };
          } else {
            console.log("Array index out of range or array is empty.");
            return;
          }
          await updateDoc(docRef, updatedData);
          setAddDate(null);
          setNote("");
          setEditId(null);
          setAmount(0);
          getExpenses();
        } else {
          console.log("No such document exists.");
        }
        setSaveLoading(false);
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("id null");
    }
  };
  // console.log(expenceses)
  return (
    <div className="row">
      <div class="card-body">
        <Grid container spacing={1} sx={{ flexGrow: 1 }}>
          <Grid xs={6} md={3}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={addDate}
                onChange={(e) => setAddDate(e)}
                format="DD/MM/YYYY"
              />
            </LocalizationProvider>
          </Grid>
          <Grid xs={6} md={3}>
            <Input
              type={"number"}
              value={amount}
              startDecorator={<EuroIcon />}
              onChange={(e) => setAmount(e.target.value)}
              size="lg"
              sx={{ fontWeight: 600, height: "55px" }}
            ></Input>
          </Grid>
          <Grid xs={6} md={3}>
            <Input
              placeholder="Note"
              value={note}
              onChange={(e) => setNote(e.target.value)}
              size="lg"
              sx={{ fontWeight: 600, height: "55px" }}
            ></Input>
          </Grid>
          <Grid xs={6} md={3}>
            {editId !== null ? (
              <Box sx={{ display: "flex", gap: 1 }}>
                <IconButton color="error" onClick={editCancel}>
                  <CancelIcon size={15} color={"error"} />
                </IconButton>
                <Button
                  type="button"
                  class="btn btn-primary"
                  onClick={editFunction}
                  loading={saveLoading}
                  sx={{ fontWeight: 600, height: "55px" }}
                >
                  Mise à jour
                </Button>
              </Box>
            ) : (
              <Button
                type="submit"
                class="btn btn-primary"
                onClick={savePaymentHandler}
                loading={saveLoading}
                sx={{ fontWeight: 600, height: "55px" }}
              >
                Sauvegarder
              </Button>
            )}
          </Grid>
        </Grid>
        <div className="row">
          <div className="expence-data">
            {loading && <LinearProgress />}
            <table class="table table-striped" style={{ fontWeight: 700 }}>
              <thead>
                <tr>
                  <th scope="col">Date</th>
                  <th scope="col">Montante</th>
                  <th scope="col">Note</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {expenceses.map((item, index) => (
                  <tr key={index}>
                    <td>{moment(item.date).format("DD-MM-YYYY")}</td>
                    <td>
                      <EuroIcon sx={{ fontSize: 18 }} />
                      {item.amount}
                    </td>
                    <td>{item.note}</td>
                    <td>
                      <IconButton
                        variant="text"
                        size="medium"
                        onClick={() => editHandl(item.id)}
                      >
                        <EditIcon size={15} color={"success"} />
                      </IconButton>
                      <IconButton
                        variant="text"
                        size="medium"
                        onClick={() => deleteHandl(item.id)}
                      >
                        <DeleteIcon size={15} color={"error"} />
                      </IconButton>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td style={{ color: "green" }}>Totale</td>
                  <td style={{ color: "green" }}>
                    <EuroIcon sx={{ fontSize: 18 }} />
                    {total}
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Expencese;

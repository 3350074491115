import { collection, doc, setDoc } from "firebase/firestore";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../Firebase";
import "./addPerson.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Paper, Divider } from "@mui/material";

function AddPerson() {
  const [loading, setLoading] = useState(false);
  const [nom, setNom] = useState("");
  const [address, setAddress] = useState("");
  const [tell, setTell] = useState("");
  const [mail, setMail] = useState("");

  const navigate = useNavigate();

  const saveHandler = async (e) => {
    e.preventDefault();
    // save to the firebase database
    setLoading(true);
    try {
      const projectRef = doc(collection(db, "persones"));
      await setDoc(projectRef, {
        nom,
        address,
        tell,
        mail,
      });
      setLoading(false);
      navigate("/persons");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="container">
      <Paper elevation={3} style={{ marginTop: 10, padding: 15 }}>
        <div className="row" style={{ padding: 10 }}>
          <div
            className="col-5"
            style={{ display: "flex", alignItems: "center" }}
          >
            <ArrowBackIcon
              size={20}
              color="primary"
              onClick={() => navigate("/persons")}
            />
          </div>
        </div>
        <Divider color="#2e7d32" />
        <form className="add-employee" onSubmit={saveHandler}>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div class="mb-3">
                <label for="nom" class="form-label">
                  Nom
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="nom"
                  value={nom}
                  onChange={(e) => setNom(e.target.value)}
                />
              </div>
              <div class="mb-3">
                <label for="prenom" class="form-label">
                  Address
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="prenom"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
              <div class="mb-3">
                <label for="jobTitle" class="form-label">
                  mail
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="jobTitle"
                  value={mail}
                  onChange={(e) => setMail(e.target.value)}
                />
              </div>
              <div class="mb-3">
                <label for="tel" class="form-label">
                  Tell
                </label>
                <input
                  type="text"
                  class="form-control"
                  id="tel"
                  value={tell}
                  onChange={(e) => setTell(e.target.value)}
                />
              </div>
            </div>
          </div>
          <button type="submit" class="btn btn-primary saveBtn">
            Sauvegarder
          </button>
        </form>
      </Paper>
    </div>
  );
}

export default AddPerson;

import { arrayUnion, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../../Firebase";
import "./notes.css";

function Notes({ id }) {
  const [saveLoading, setSaveLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [note, setNote] = useState("");
  const [notes, setNotes] = useState([]);
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    getNotes();
  }, []);

  const getNotes = async () => {
    if (id !== null) {
      try {
        setLoading(true);
        const docRef = doc(db, "personNotes", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setNotes(docSnap.data().notes);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const saveNoteHandler = async () => {
    if (id !== null) {
      try {
        setSaveLoading(true);
        const docRef = doc(db, "personNotes", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          await updateDoc(docRef, {
            notes: arrayUnion({ note, date: new Date().toDateString() }),
          });
        } else {
          await setDoc(docRef, {
            notes: [{ note, date: new Date().toDateString() }],
          });
        }
        getNotes();
        setNote("");
        setSaveLoading(false);
      } catch (error) {}
    }
  };

  const deleteHandl = async (index) => {
    if (index !== null) {
      try {
        setLoading(true);
        const docRef = doc(db, "personNotes", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const updatedData = { ...docSnap.data() };
          const newArray = [...updatedData.notes];
          newArray.splice(index, 1);
          await updateDoc(docRef, {
            notes: newArray,
          });
          getNotes();
          setNote("");
        } else {
          console.log("No such document exists.");
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("id null");
    }
    setLoading(false);
  };

  const editHandl = (index) => {
    console.log(index);
    if (index !== null) {
      setEditId(index);
      setNote(notes[index].note);
    }
  };
  const editCancel = () => {
    setEditId(null);
    setNote("");
  };

  const editFunction = async () => {
    if (editId !== null) {
      try {
        setSaveLoading(true);
        const docRef = doc(db, "personNotes", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const updatedData = { ...docSnap.data() };
          if (updatedData.notes && updatedData.notes.length > editId) {
            updatedData.notes[editId] = {
              ...updatedData.notes[editId],
              note: note,
            };
          } else {
            console.log("Array index out of range or array is empty.");
            return;
          } // this is for the case
          await updateDoc(docRef, updatedData);
          setNote("");
          setEditId(null);
          getNotes();
        } else {
          console.log("No such document exists.");
        }
        setSaveLoading(false);
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("id null");
    }
  };

  return (
    <div className="row">
      <div className="card" style={{ margin: 0 }}>
        <div class="card-body">
          <form className="notes-form">
            <div className="col-md-8 col-sm-12">
              <div className="form-group">
                <input
                  class="form-control"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              {editId !== null ? (
                <>
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={editCancel}
                  >
                    ....
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={editFunction}
                  >
                    {saveLoading ? "Saving..." : "mise à jour"}
                  </button>
                </>
              ) : (
                <div className="form-group">
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={saveNoteHandler}
                  >
                    {saveLoading ? "Saving..." : "Sauvegarder"}
                  </button>
                </div>
              )}
            </div>
          </form>
          <div className="notes-data">
            <p>Remarques</p>
            <table class="table table-striped">
              <tbody>
                {notes.map((item, index) => (
                  <tr key={index}>
                    <td className="singleNote">{item.note}</td>
                    <td>
                      <button
                        className="btn btn-primary"
                        onClick={() => editHandl(index)}
                      >
                        edit
                      </button>
                      <button
                        className="btn btn-danger"
                        onClick={() => deleteHandl(index)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Notes;

import {
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  Divider,
  Grid,
  LinearProgress,
  Paper,
} from "@mui/material";
import { collection, getDocs } from "firebase/firestore";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { db } from "../../Firebase";
import { setUser } from "../../stores/reducers/userSlice";
import "./employees.css";
import Box from "@mui/material/Box";

function Employees() {
  const navigate = useNavigate();
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    getEmployees();
  }, []);

  const getEmployees = async () => {
    setLoading(true);
    const querySnapshot = await getDocs(collection(db, "employee"));
    const data = [];
    querySnapshot.forEach((doc) => {
      data.push({ ...doc.data(), id: doc.id });
    });
    setEmployees(data);
    setLoading(false);
  };

  const navigateTodetail = (id) => {
    const employeeData = employees.filter((item) => item.id === id);
    dispatch(setUser(employeeData));
    navigate(`/employeedetails`);
  };

  return (
    <>
      <CssBaseline />
      <Container maxWidth="md">
        <Paper elevation={3}>
          <Box mt={2}>
            {loading ? <LinearProgress color="primary" /> : null}
            <Grid container padding={2}>
              <Grid md={4} xs={6}>
                <h4>Employées</h4>
              </Grid>
              <Grid md={4} xs={0}></Grid>
              <Grid md={4} xs={6} textAlign="right">
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => navigate("/addemployee")}
                >
                  Ajouter
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Divider color="#2e7d32" />
          <div class="">
            <div className="body">
              <div
                class="list-group"
                style={{ borderRadius: 0, border: "none" }}
              >
                {employees.map((employee, index) => (
                  <div
                    onClick={() => navigateTodetail(employee.id)}
                    key={index}
                    href="#"
                    class="list-group-item list-group-item-action employee-card d-flex justify-content-st"
                    style={{
                      borderBottom: "1px solid #c2c2c2",
                      borderTop: "none",
                      borderRight: "none",
                      borderLeft: "none",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <img
                        className="employee-avatar"
                        src={
                          employee.profileImage
                            ? employee.profileImage
                            : "/workericon.png"
                        }
                        alt="profileimage"
                      />
                    </div>
                    <div>
                      <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-0">{employee.nom}</h5>
                      </div>
                      {employee.jobTitle && (
                        <p class="mb-1">{employee.jobTitle}</p>
                      )}
                      {employee.ssn && (
                        <small class="text-body-secondary">
                          {employee.ssn}
                        </small>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Paper>
      </Container>
    </>
  );
}

export default Employees;

import { Paper } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Expencese from "./expencese/Expencese";
import Notes from "./notes/Notes";
import ProjectPayment from "./payment/ProjectPayment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { deleteDoc, doc } from "firebase/firestore";
import { db } from "../../Firebase";
import "./personDetails.css";
import { Box, Container, CssBaseline } from "@mui/joy";

function PersonDetails() {
  const [selectedTab, setSelectedTab] = useState("Details");
  const { id, nom, address, mail, tell } = useSelector(
    (state) => state.person.person[0]
  );

  const navigate = useNavigate();

  const tabs = [
    { label: "Details", value: "Details" },
    { label: "Coming", value: "Coming" },
    { label: "Outgoing", value: "Outgoing" },
    { label: "Notes", value: "Notes" },
  ];

  const handleDeleteProject = async (e) => {
    e.preventDefault();
    const projectId = id;
    const confirmed = window.confirm("Confirmation de la suppression");
    if (confirmed) {
      try {
        await deleteDoc(doc(db, "persones", projectId));
        navigate("/persons");
      } catch (error) {
        console.error("Error removing document: ", error);
      }
    }
  };

  return (
    <>
      <CssBaseline />
      <Container maxWidth="md">
        <Paper elevation={3} style={{ marginTop: 10 }}>
          <Box mt={2}>
            <div className="row" style={{ padding: 10 }}>
              <div
                className="col-5"
                style={{ display: "flex", alignItems: "center" }}
              >
                <ArrowBackIcon
                  size={20}
                  color="primary"
                  onClick={() => navigate("/persons")}
                />
                <div className="profile-card">
                  <div className="profile-name" style={{ marginLeft: 5 }}>
                    <h4>{`${nom}`}</h4>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="details-body">
                <ul
                  class="nav nav-pills nav-fill"
                  style={{
                    padding: 5,
                    borderTop: "2px solid #1976d261",
                    borderBottom: "2px solid rgb(25 118 210 / 18%)",
                  }}
                >
                  {tabs.map((tab, index) => (
                    <li key={index} class="nav-item">
                      <p
                        style={{ margin: 0 }}
                        className={
                          tab.label === selectedTab
                            ? "nav-link tab-menu active"
                            : "nav-link tab-menu"
                        }
                        onClick={() => setSelectedTab(tab.label)}
                      >
                        {tab.value}
                      </p>
                    </li>
                  ))}
                </ul>
                <div className="tab-content">
                  {selectedTab === "Details" && (
                    <>
                      <div className="row detailsData">
                        <div className="col-12">
                          <div className="form-group">
                            <div class="row">
                              <label class="col-sm-4">Nom:</label>
                              <div class="col-sm-8">
                                <p>{nom}</p>
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <div class="row">
                              <label class="col-sm-4">Adress:</label>
                              <div class="col-sm-8">
                                <p>{address}</p>
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <div class="row">
                              <label class="col-sm-4">Mail:</label>
                              <div class="col-sm-8">
                                <p>{mail}</p>
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <div class="row">
                              <label class="col-sm-4">Tell: </label>
                              <div class="col-sm-8">
                                <p>{tell}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="detail-action">
                        <div className="row">
                          <div className="col-12">
                            {/* <button
                              type="button"
                              class="btn btn-primary"
                              onClick={() => navigate("/editchantier")}
                              style={{ marginRight: "10px" }}
                            >
                              Modifier
                            </button> */}
                            <button
                              type="button"
                              class="btn btn-danger"
                              onClick={handleDeleteProject}
                            >
                              Supprimer
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {selectedTab === "Coming" && <ProjectPayment id={id} />}

                  {selectedTab === "Outgoing" && <Expencese id={id} />}

                  {selectedTab === "Notes" && <Notes id={id} />}
                </div>
              </div>
            </div>
          </Box>
        </Paper>
      </Container>
    </>
  );
}

export default PersonDetails;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    person: null, // Initial person state is null
};

const personSlice = createSlice({
  name: 'person',
  initialState,
  reducers: {
    setPerson(state, action) {
      state.person = action.payload;
    },
    clearPerson(state) {
      state.person = null;
    },
  },
});

export const { setPerson, clearPerson } = personSlice.actions;
export default personSlice.reducer;

import React, { useState } from "react";
import { Box, CircularProgress, Grid, Paper } from "@mui/material";
import Button from "@mui/material/Button";
import { auth } from "../../Firebase";
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
} from "firebase/auth";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setlogin } from "../../stores/reducers/loginSlice";
import { toast } from "react-toastify";

function Settings() {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    const user = auth.currentUser;
    if(!user) {
      toast("Veuillez vous reconnecter", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        progress: undefined,
        theme: "light",
      });
      dispatch(setlogin({ loggedIn: false }));
      return
    }
    setUser(user.email);
  }, []);

  const handleChangePassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    const user = auth.currentUser;
    if (user) {
      try {
        const credential = EmailAuthProvider.credential(
          user.email,
          oldPassword
        );
        await reauthenticateWithCredential(auth.currentUser, credential);
        await updatePassword(auth.currentUser, newPassword);
        alert("Password updated successfully!");
      } catch (error) {
        console.error("Error changing password: ", error);
        alert("Error changing password. Please try again.");
      }
    }
    setLoading(false);
  };

  return (
    <>
      <div className="container">
        <Box sx={{ flexGrow: 1 }}></Box>

        <Paper elevation={3} style={{ marginTop: 10 }}>
          <div className="row" style={{ padding: 10 }}>
            <p>Paramètres</p>
            <form>
              <div className="col-6">
                <p style={{ fontStyle: "italic" }}>User: {user}</p>
              </div>
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <label className="form-label">Ancien mot de passe</label>
                  <input
                    type="password"
                    className="form-control"
                    aria-describedby="emailHelp"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <label className="form-label">nouveau mot de passe</label>
                  <input
                    type="password"
                    className="form-control"
                    aria-describedby="emailHelp"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </Grid>
              </Grid>
              <div className="mb-3 mt-4">
                <Button
                  onClick={handleChangePassword}
                  variant="contained"
                  color="success"
                >
                  {loading ? (
                    <CircularProgress size={25} color="inherit" />
                  ) : (
                    "Mise à jour"
                  )}
                </Button>
              </div>
            </form>
          </div>
        </Paper>
      </div>
    </>
  );
}

export default Settings;

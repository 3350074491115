import React, { useState } from "react";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { db, storage } from "../../Firebase";
import { collection, doc, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./addEmployee.css";
import { CircularProgress, Divider, Paper } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function AddEmployee() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [address, setAddress] = useState("");
  const [dob, setDob] = useState("");
  const [ssn, setSsn] = useState("");
  const [tel, setTel] = useState("");
  const [mail, setMail] = useState("");
  const [pays, setPays] = useState("");

  const handleFileChange = async (e) => {
    if (e.target.files[0]) {
      const file_data = e.target.files[0];
      const storageRef = ref(storage, "employee/" + file_data.name);
      await uploadBytes(storageRef, file_data);
      console.log("File uploaded successfully!");
      const url = await getDownloadURL(storageRef);
      setProfileImage(url);
      console.log("Download URL:", url);
    }
  };

  // save user data to firestore
  const saveUserData = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // Add a new document with a generated id
      const newEmplRef = doc(collection(db, "employee"));
      // later...
      await setDoc(newEmplRef, {
        profileImage,
        nom,
        prenom,
        jobTitle,
        address,
        dob,
        ssn,
        tel,
        mail,
        pays,
      });
      setLoading(false);
      toast("Employé ajouté", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        progress: undefined,
        theme: "light",
      });
      navigate("/employees");
    } catch (error) {
      console.error("Error saving user data: ", error);
    }
    setLoading(false);
  };

  return (
    <div className="container">
      <Paper elevation={3} style={{ marginTop: 10, padding: 15 }}>
        <div className="row" style={{ padding: 10 }}>
          <div
            className="col-5"
            style={{ display: "flex", alignItems: "center" }}
          >
            <ArrowBackIcon
              size={20}
              color="primary"
              onClick={() => navigate("/employees")}
            />
            <div className="profile-card">
              <div className="profile-image">
                <img
                  src={
                    profileImage
                      ? profileImage
                      : "/workericon.png"
                  }
                  alt="profile"
                />
              </div>
              <div className="profile-name">
                <h4>{`${nom} ${prenom}`}</h4>
              </div>
            </div>
          </div>
        </div>
        <Divider color="#2e7d32" />
        <div class="card-body">
          <form className="add-employee" onSubmit={saveUserData}>
            <div className="row">
              <div class="col-md-6 col-sm-12">
                <div class="mb-3">
                  <label for="image" class="form-label">
                    Photo
                  </label>
                  <input
                    type="file"
                    class="form-control"
                    id="image"
                    onChange={handleFileChange}
                  />
                </div>
                <div class="mb-3">
                  <label for="nom" class="form-label">
                    Nom
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="nom"
                    value={nom}
                    onChange={(e) => setNom(e.target.value)}
                  />
                </div>
                <div class="mb-3">
                  <label for="prenom" class="form-label">
                    Prénom
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="prenom"
                    value={prenom}
                    onChange={(e) => setPrenom(e.target.value)}
                  />
                </div>
                <div class="mb-3">
                  <label for="jobTitle" class="form-label">
                    Poste de Travail
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="jobTitle"
                    value={jobTitle}
                    onChange={(e) => setJobTitle(e.target.value)}
                  />
                </div>
                <div class="mb-3">
                  <label for="address" class="form-label">
                    Adresse
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="mb-3">
                  <label for="dob" class="form-label">
                    Né(e) Le
                  </label>
                  <input
                    type="date"
                    class="form-control"
                    placeholder="dd/mm/yyyy"
                    id="dob"
                    value={dob}
                    onChange={(e) => setDob(e.target.value)}
                  />
                </div>
                <div class="mb-3">
                  <label for="ssn" class="form-label">
                    N° Sécurité Social
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="ssn"
                    value={ssn}
                    onChange={(e) => setSsn(e.target.value)}
                  />
                </div>
                <div class="mb-3">
                  <label for="tel" class="form-label">
                    Tel
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="tel"
                    value={tel}
                    onChange={(e) => setTel(e.target.value)}
                  />
                </div>
                <div class="mb-3">
                  <label for="mail" class="form-label">
                    Mail
                  </label>
                  <input
                    type="email"
                    class="form-control"
                    id="mail"
                    value={mail}
                    onChange={(e) => setMail(e.target.value)}
                  />
                </div>
                <div class="mb-3">
                  <label for="pays" class="form-label">
                    Pays
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="pays"
                    value={pays}
                    onChange={(e) => setPays(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <button type="submit" class="btn btn-primary saveBtn">
              {loading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                "Sauvegarder"
              )}
            </button>
          </form>
        </div>
      </Paper>
    </div>
  );
}

export default AddEmployee;

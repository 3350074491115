import { combineReducers } from 'redux';
import userReducer from './userSlice';
import projectReducer from './projectSlice';
import loginSlice from './loginSlice';
import personSlice from './personSlice';

const rootReducer = combineReducers({
  user: userReducer,
  project: projectReducer,
  login: loginSlice,
  person: personSlice
  // Add other reducers here
});

export default rootReducer;

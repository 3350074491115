import { IconButton } from "@mui/material";
import { arrayUnion, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { db } from "../../../Firebase";
import "./projectPayment.css";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  Box,
  Grid,
  Input,
  Option,
  Select,
  Button,
  LinearProgress,
  Divider,
} from "@mui/joy";
import EuroIcon from "@mui/icons-material/Euro";
import dayjs from "dayjs";
import { v4 as uuidv4 } from 'uuid';

function ProjectPayment() {
  const { id } = useSelector((state) => state.person.person[0]);
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [addDate, setAddDate] = useState(null);
  const [selectedMethod, setSelectedMethod] = useState("");
  const [amount, setAmount] = useState("");
  const [note, setNote] = useState("");
  const [payments, setPayments] = useState([]);
  const [total, setTotal] = useState(0);
  const [editId, setEditId] = useState(null);

  const paymentMethods = [
    { label: "Espèces", value: "Espèces" },
    { label: "banque", value: "banque" },
  ];

  useEffect(() => {
    getPayments();
  }, []);

  const getPayments = async () => {
    if (id !== null) {
      try {
        setLoading(true);
        const docRef = doc(db, "personPayments", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const payments = docSnap.data().payments;
          // get total payments ammount
          const orderedPayments = payments.slice().sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            return dateB - dateA;
          });
          setPayments(orderedPayments);
          let total = orderedPayments.reduce(
            (acc, expense) => acc + parseInt(expense.amount),
            0
          );
          setTotal(total);
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const savePaymentHandler = async () => {
    setSaveLoading(true);
    if (id !== null) {
      try {
        const docRef = doc(db, "personPayments", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          await updateDoc(docRef, {
            payments: arrayUnion({
              id: uuidv4(), // Generate UUID as ID
              date: new Date(addDate).toDateString(),
              selectedMethod,
              amount,
              note,
            }),
          });
        } else {
          await setDoc(docRef, {
            payments: [
              {
                id: uuidv4(), // Generate UUID as ID
                date: new Date(addDate).toDateString(),
                selectedMethod,
                amount,
                note,
              },
            ],
          });
        }
        getPayments();
        setAmount("");
        setNote("");
        setAddDate(null);
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("id null");
    }
    setSaveLoading(false);
  };

  const deleteHandl = async (index) => {
    const confirmed = window.confirm("Confirmation de la suppression");
    if (confirmed) {
      if (index !== null) {
        try {
          setLoading(true);
          const docRef = doc(db, "personPayments", id);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const updatedData = { ...docSnap.data() };
            const newArray = [...updatedData.payments];
            //newArray.splice(index, 1);
            var lists = newArray.filter(x => {
              return x.id != index;
            })
            await updateDoc(docRef, {
              payments: lists,
            });
            getPayments();
          } else {
            console.log("No such document exists.");
          }
          setLoading(false);
        } catch (error) {
          console.log(error);
        }
      } else {
        console.log("id null");
      }
    }
  };

  const editHandl = async (itemId, method) => {
    if (itemId !== null) {
      const value = payments.find((item) => item.id === itemId);
      setEditId(value.id);
      setAddDate(dayjs(value.date));
      setSelectedMethod(method);
      setAmount(value.amount);
      setNote(value.note);
    }
  };

  const editCancel = (e) => {
    e.preventDefault();
    setEditId(null);
    setAddDate(null);
    setAmount("");
    setNote("");
  };

  const editFunction = async (e) => {
    e.preventDefault();
    if (editId !== null) {
      try {
        setSaveLoading(true);
        const docRef = doc(db, "personPayments", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const updatedData = { ...docSnap.data() };
          const editedItemIndex = updatedData.payments.findIndex(item => item.id === editId);
          if (editedItemIndex !== null) {
            updatedData.payments[editedItemIndex] = {
              id: editId,
              date: new Date(addDate).toDateString(),
              selectedMethod,
              amount,
              note,
            };
          } else {
            console.log("Array index out of range or array is empty.");
            return;
          }
          await updateDoc(docRef, updatedData);
          setEditId(null);
          setAmount("");
          setNote("");
          setAddDate(null);
          getPayments();
        } else {
          console.log("No such document exists.");
        }
        setSaveLoading(false);
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("id null");
    }
  };
  return (
    <div className="row">
      <div class="card-body">
        <Grid container spacing={1} sx={{ flexGrow: 1 }}>
          <Grid xs={6} md={3}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={addDate}
                onChange={(e) => setAddDate(e)}
                format="DD/MM/YYYY"
              />
            </LocalizationProvider>
          </Grid>
          <Grid xs={6} md={3}>
            <Select
              defaultValue="Espèces"
              onChange={(e, value) => setSelectedMethod(value)}
              sx={{ fontWeight: 600, height: "55px" }}
            >
              {paymentMethods.map((item, index) => (
                <Option
                  value={item.value}
                  selected={item.value === selectedMethod}
                  key={index}
                >
                  {item.label}
                </Option>
              ))}
            </Select>
          </Grid>
          <Grid xs={6} md={3}>
            <Input
              type={"number"}
              value={amount}
              startDecorator={<EuroIcon />}
              onChange={(e) => setAmount(e.target.value)}
              size="lg"
              sx={{ fontWeight: 600, height: "55px" }}
            ></Input>
          </Grid>
          <Grid xs={6} md={3}>
            <Input
              placeholder="Note"
              value={note}
              onChange={(e) => setNote(e.target.value)}
              size="lg"
              sx={{ fontWeight: 600, height: "55px" }}
            ></Input>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          justifyContent="flex-end"
          sx={{ flexGrow: 1 }}
        >
          <Grid xs={6} md={3}>
            {editId !== null ? (
              <Box sx={{ display: "flex", gap: 1 }}>
                <IconButton color="error" onClick={editCancel}>
                  <CancelIcon size={15} color={"error"} />
                </IconButton>
                <Button
                  type="button"
                  class="btn btn-primary"
                  onClick={editFunction}
                  loading={saveLoading}
                  sx={{ fontWeight: 600, height: "55px" }}
                >
                  Mise à jour
                </Button>
              </Box>
            ) : (
              <Button
                type="submit"
                class="btn btn-primary"
                onClick={savePaymentHandler}
                loading={saveLoading}
                sx={{ fontWeight: 600, height: "55px" }}
              >
                Sauvegarder
              </Button>
            )}
          </Grid>
        </Grid>
        <Divider orientation="vertical" />
        <div className="expence-data">
          {loading && <LinearProgress />}
          <table class="table table-striped projectPaymentData">
            <thead>
              <tr>
                <th scope="col">Date</th>
                <th scope="col">Montante</th>
                <th scope="col">Note</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {payments.map((item, index) => (
                <tr key={index}>
                  <td>{moment(item.date).format("DD-MM-YYYY")}</td>
                  <td>
                    <EuroIcon sx={{ fontSize: 18 }} /> {item.amount} -{" "}
                    {item.selectedMethod}
                  </td>
                  <td>{item.note}</td>
                  <td>
                    <IconButton
                      variant="text"
                      size="medium"
                      onClick={() => editHandl(item.id, item.selectedMethod)}
                    >
                      <EditIcon size={15} color={"success"} />
                    </IconButton>
                    <IconButton
                      variant="text"
                      size="medium"
                      onClick={() => deleteHandl(item.id)}
                    >
                      <DeleteForeverIcon size={15} color={"error"} />
                    </IconButton>
                  </td>
                </tr>
              ))}
              <tr>
                <td style={{ color: "green" }}>Totale</td>
                <td style={{ color: "green" }}>
                  <EuroIcon sx={{ fontSize: 18 }} />
                  {total}
                </td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ProjectPayment;

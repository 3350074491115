import { signInWithEmailAndPassword } from "firebase/auth";
import React, { useState } from "react";
import { auth } from "../Firebase";
import "./login.css";
import { MdLogin } from "react-icons/md";

// import * as React from 'react';
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CircularProgress } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { useDispatch } from "react-redux";
import { setlogin } from "../stores/reducers/loginSlice";

const defaultTheme = createTheme();

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const dispatch =  useDispatch()

  const loginHandle = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (email && password) {
      try {
        await signInWithEmailAndPassword(auth, email, password);
        dispatch(setlogin({loggedIn: true}))
      } catch (error) {
        setError(true);
        console.log("Error in login", error);
      }
    } else {
      alert("Enter email and password");
    }
    setLoading(false);
  };

  return (
    <>
      <ThemeProvider theme={defaultTheme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Card>
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                  Sign in
                </Typography>
                <Box component="form" noValidate sx={{ mt: 1 }}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Mail"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <Button
                    type="submit"
                    onClick={loginHandle}
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    {loading ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      "Sign In"
                    )}
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Box>
          <Snackbar
            open={error}
            autoHideDuration={1000}
            message="User/Password wrong."
            onClose={null}
          />
        </Container>
      </ThemeProvider>
    </>
  );
}

export default Login;

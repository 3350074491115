import React, { useEffect, useState } from "react";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { db, storage } from "../../Firebase";
import { collection, doc, getDoc, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "./addEmployee.css";
import { CircularProgress, Divider, Paper } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { setUser } from "../../stores/reducers/userSlice";
import LinearProgress from "@mui/material/LinearProgress";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function EditEmployee() {
  const navigate = useNavigate();
  const { id } = useSelector((state) => state.user.user[0]);
  const [loading, setLoading] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [nom, setNom] = useState("");
  const [prenom, setPrenom] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [address, setAddress] = useState("");
  const [dob, setDob] = useState("");
  const [ssn, setSsn] = useState("");
  const [tel, setTel] = useState("");
  const [mail, setMail] = useState("");
  const [pays, setPays] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    getEmployee();
  }, []);

  const getEmployee = async () => {
    if (id !== null) {
      setLoading(true);
      try {
        const employeeRef = doc(db, "employee", id);
        const employeeSnap = await getDoc(employeeRef);
        if (employeeSnap.exists()) {
          setNom(employeeSnap.data().nom);
          setPrenom(employeeSnap.data().prenom);
          setJobTitle(employeeSnap.data().jobTitle);
          setAddress(employeeSnap.data().address);
          setDob(employeeSnap.data().dob);
          console.log(employeeSnap.data().dob);
          setSsn(employeeSnap.data().ssn);
          setTel(employeeSnap.data().tel);
          setMail(employeeSnap.data().mail);
          setPays(employeeSnap.data().pays);
          setProfileImage(employeeSnap.data().profileImage);
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.log("Error getting document:", error);
      }
      setLoading(false);
    }
  };

  const handleFileChange = async (e) => {
    if (e.target.files[0]) {
      const file_data = e.target.files[0];
      const storageRef = ref(storage, "employee/" + file_data.name);
      await uploadBytes(storageRef, file_data);
      console.log("File uploaded successfully!");
      const url = await getDownloadURL(storageRef);
      setProfileImage(url);
      console.log("Download URL:", url);
    }
  };

  const updateUserData = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const employeeRef = doc(db, "employee", id);
      await setDoc(employeeRef, {
        profileImage,
        nom,
        prenom,
        jobTitle,
        address,
        dob,
        ssn,
        tel,
        mail,
        pays,
      });
      const docSnap = await getDoc(employeeRef);
      if (docSnap.exists()) {
        const savedData = docSnap.data();
        savedData.id = id;
        dispatch(setUser([savedData]));
        navigate("/employeedetails");
      } else {
        console.log("No such document exists.");
      }
      setLoading(false);
    } catch (error) {
      console.error("Error updating user data: ", error);
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <Paper elevation={3} style={{ marginTop: 10, padding: 15 }}>
        {loading && <LinearProgress />}
        <div className="row" style={{ padding: 10 }}>
          <div
            className="col-5"
            style={{ display: "flex", alignItems: "center" }}
          >
            <ArrowBackIcon
              size={20}
              color="primary"
              onClick={() => navigate("/employeedetails")}
            />
            <div className="profile-card">
              <div className="profile-image">
                <img
                  src={
                    profileImage
                      ? profileImage
                      : "/workericon.png"
                  }
                  alt="profile"
                />
              </div>
            </div>
          </div>
        </div>
        <Divider color="#2e7d32" />
        <div class="card-body">
          <form className="add-employee">
            <div className="row">
              <div class="col-md-6 col-sm-12">
                <div class="mb-3">
                  <label for="image" class="form-label">
                    Photo
                  </label>
                  <input
                    type="file"
                    class="form-control"
                    id="image"
                    onChange={handleFileChange}
                  />
                </div>
                <div class="mb-3">
                  <label for="nom" class="form-label">
                    Nom
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="nom"
                    value={nom}
                    onChange={(e) => setNom(e.target.value)}
                  />
                </div>
                <div class="mb-3">
                  <label for="prenom" class="form-label">
                    Prénom
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="prenom"
                    value={prenom}
                    onChange={(e) => setPrenom(e.target.value)}
                  />
                </div>
                <div class="mb-3">
                  <label for="jobTitle" class="form-label">
                    Poste de Travail
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="jobTitle"
                    value={jobTitle}
                    onChange={(e) => setJobTitle(e.target.value)}
                  />
                </div>
                <div class="mb-3">
                  <label for="address" class="form-label">
                    Adresse
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="mb-3">
                  <label for="dob" class="form-label">
                    Né(e) Le
                  </label>
                  <input
                    type="date"
                    class="form-control"
                    placeholder="dd/mm/yyyy"
                    id="dob"
                    value={moment(dob).format("YYYY-MM-DD")}
                    onChange={(e) => setDob(e.target.value)}
                  />
                </div>
                <div class="mb-3">
                  <label for="ssn" class="form-label">
                    N° Sécurité Social
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="ssn"
                    value={ssn}
                    onChange={(e) => setSsn(e.target.value)}
                  />
                </div>
                <div class="mb-3">
                  <label for="tel" class="form-label">
                    Tel
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="tel"
                    value={tel}
                    onChange={(e) => setTel(e.target.value)}
                  />
                </div>
                <div class="mb-3">
                  <label for="mail" class="form-label">
                    Mail
                  </label>
                  <input
                    type="email"
                    class="form-control"
                    id="mail"
                    value={mail}
                    onChange={(e) => setMail(e.target.value)}
                  />
                </div>
                <div class="mb-3">
                  <label for="pays" class="form-label">
                    Pays
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="pays"
                    value={pays}
                    onChange={(e) => setPays(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <button
              type="submit"
              onClick={updateUserData}
              class="btn btn-primary saveBtn"
            >
              Sauvegarder
            </button>
          </form>
        </div>
      </Paper>
    </div>
  );
}

export default EditEmployee;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    login: {loggedIn: false}, // Initial login state is null
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setlogin(state, action) {
      state.login = action.payload;
    },
    clearlogin(state) {
      state.login = false;
    },
  },
});

export const { setlogin, clearlogin } = loginSlice.actions;
export default loginSlice.reducer;

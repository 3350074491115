import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimeField } from "@mui/x-date-pickers";
import moment from "moment/moment";
import { differenceInMinutes } from "date-fns";
import EditIcon from "@mui/icons-material/Edit";
import {
  arrayUnion,
  deleteDoc,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../Firebase";
import { toast } from "react-toastify";
import Payment from "./payment/Payment";
import Notes from "./notes/Notes";
import Paper from "@mui/material/Paper";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Divider, Grid, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { LinearProgress, IconButton, Box, Button } from "@mui/joy";
import CancelIcon from "@mui/icons-material/Cancel";
import dayjs from "dayjs";
import "./employeeDetails.css";
import { v4 as uuidv4 } from 'uuid';

function EmployeeDetails() {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("Details");
  const [date, setDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [timeRange, setTimeRange] = useState(0);
  const [note, setNote] = useState("");
  const [loading, setLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [workHistory, setWorkHistory] = useState([]);
  const [totalWorkingHours, setTotalWorkingHours] = useState(0);
  const [editId, setEditId] = useState(null);
  const {
    profileImage,
    nom,
    prenom,
    jobTitle,
    address,
    dob,
    ssn,
    tel,
    mail,
    pays,
    id,
  } = useSelector((state) => state.user.user[0]);

  const tabs = [
    { label: "Details" },
    { label: "Works" },
    { label: "Payment" },
    { label: "Notes" },
  ];

  useEffect(() => {
    getData();
  }, []);
  // const getDatas = async () => {
  //   try {
  //     const value = id;
  //     if (value !== null) {
  //       setLoading(true);
  //       const docRef = doc(db, "workinghours", value);
  //       const docSnap = await getDoc(docRef);

  //       if (docSnap && docSnap.exists()) {
  //         const dataArray = docSnap.data().dataArray || [];
  //         const customSort = (a, b) => {
  //           const datePartsA = a.date.split("/").reverse(); // Split the date string and reverse it to get "YYYY/MM/DD" format
  //           const datePartsB = b.date.split("/").reverse(); // Split the date string and reverse it to get "YYYY/MM/DD" format

  //           const dateA = new Date(datePartsA.join("/")); // Join the date parts and create a Date object
  //           const dateB = new Date(datePartsB.join("/")); // Join the date parts and create a Date object

  //           return dateB - dateA; // Sort in descending order (latest date first)
  //         };
  //         // Create a copy of expenses array and sort it using the custom sort function
  //         const orderedPayments = dataArray.slice().sort(customSort);
  //         setWorkHistory(orderedPayments);
  //         // rewrie the code below
  //         if (dataArray.length > 0) {
  //           const filteredItems = docSnap
  //             .data()
  //             .dataArray.filter((item) => item.totalHours !== "");
  //           let sumHoras = 0;
  //           filteredItems.forEach((item) => {
  //             sumHoras += hoursStringToDecimal(item.totalHours);
  //           });
  //           setTotalWorkingHours(decimalHoursToString(sumHoras));
  //         } else {
  //           const dataArray = docSnap.data().dataArray || [];
  //           const customSort = (a, b) => {
  //             const datePartsA = a.date.split("/").reverse(); // Split the date string and reverse it to get "YYYY/MM/DD" format
  //             const datePartsB = b.date.split("/").reverse(); // Split the date string and reverse it to get "YYYY/MM/DD" format
  //             const dateA = new Date(datePartsA.join("/")); // Join the date parts and create a Date object
  //             const dateB = new Date(datePartsB.join("/")); // Join the date parts and create a Date object
  //             return dateB - dateA; // Sort in descending order (latest date first)
  //           };
  //           // Create a copy of expenses array and sort it using the custom sort function
  //           const orderedPayments = dataArray.slice().sort(customSort);
  //           setWorkHistory(orderedPayments);
  //           console.log("No such document!", dataArray);
  //         }
  //       } else {
  //         console.log("No such document!");
  //       }
  //     }
  //   } catch (e) {
  //     // console.log(e);
  //   }
  //   setLoading(false);
  // };

  const getData = async () => {
    try {
        const value = id;
        if (value !== null) {
            setLoading(true);
            const docRef = doc(db, "workinghours", value);
            const docSnap = await getDoc(docRef);

            if (docSnap && docSnap.exists()) {
                const dataArray = docSnap.data().dataArray || [];

                // Sorting function
                const customSort = (a, b) => {
                    const datePartsA = a.date.split("/").reverse();
                    const datePartsB = b.date.split("/").reverse();
                    const dateA = new Date(datePartsA.join("/"));
                    const dateB = new Date(datePartsB.join("/"));
                    return dateB - dateA;
                };

                // Add the original ID to each item
                const dataWithIds = dataArray.map((item, index) => ({
                    ...item,
                    originalId: index, // You can change this to use the actual ID if it's available in your data
                }));

                // Filter out items with empty total hours
                const filteredItems = dataWithIds.filter((item) => item.totalHours !== "");

                if (filteredItems.length > 0) {
                    // Calculate total working hours
                    let sumHoras = 0;
                    filteredItems.forEach((item) => {
                        sumHoras += hoursStringToDecimal(item.totalHours);
                    });
                    setTotalWorkingHours(decimalHoursToString(sumHoras));
                }

                // Sort the work history array
                const orderedPayments = filteredItems.slice().sort(customSort);
                setWorkHistory(orderedPayments);
            } else {
                console.log("No such document!");
            }
        }
    } catch (e) {
        console.error("Error fetching data:", e);
    }
    setLoading(false);
};


  function hoursStringToDecimal(hoursString) {
    const [hoursPart, minutesPart] = hoursString.split(":");
    return Number(hoursPart) + Number(minutesPart) / 60;
  }

  function decimalHoursToString(hoursDecimal) {
    const numHours = Math.floor(hoursDecimal);
    const numMinutes = Math.round((hoursDecimal - numHours) * 60);
    return `${numHours < 10 ? "0" : ""}${numHours}:${
      numMinutes < 10 ? "0" : ""
    }${numMinutes}`;
  }

  const handleStartTimeChange = (newTime) => {
    setStartTime(newTime);
    calculateTimeRange(newTime, endTime);
  };

  const handleEndTimeChange = (newTime) => {
    setEndTime(newTime);
    calculateTimeRange(startTime, newTime);
  };

  const calculateTimeRange = (start, end) => {
    if (start && end) {
      const range = differenceInMinutes(new Date(end), new Date(start));
      const hours = Math.floor(range / 60);
      const minutes = range % 60;
      setTimeRange(
        `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}`
      );
    }
  };

  const handleConfirm = async () => {
    if (date !== null && timeRange > "00:00" && timeRange !== "NaN:NaN") {
      setAddLoading(true);
      const data = {
        id: uuidv4(), // Generate UUID as ID
        date: moment(new Date(date)).format("DD/MM/YYYY"),
        startTime: moment(new Date(startTime)).format("HH:mm"),
        endTime: moment(new Date(endTime)).format("HH:mm"),
        totalHours: timeRange,
        note,
        month: moment(new Date(date)).format("MM"),
      };
      try {
        const docRef = doc(db, "workinghours", id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          await updateDoc(docRef, {
            dataArray: arrayUnion(data),
          });
        } else {
          await setDoc(docRef, { dataArray: [data] });
        }

        toast("Saved", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          progress: undefined,
          theme: "light",
        });

        setStartTime(null);
        setEndTime(null);
        setTimeRange(0);
        setNote("");
        getData();
        setAddLoading(false);
      } catch (error) {
        setAddLoading(false);
      }
    } else {
      alert("Veuillez entrer une heure ou une date valide");
    }
  };

  const handleDeleteUser = async (e) => {
    e.preventDefault();
    const employeeId = id;
    const confirmed = window.confirm("Confirmation de la suppression");
    if (confirmed) {
      try {
        await deleteDoc(doc(db, "employee", employeeId));
        navigate("/employees");
      } catch (error) {
        console.error("Error removing document: ", error);
      }
    }
  };

  const deleteWorkHour = async (index) => {
    if (index !== null) {
      const employeeID = id;
      const confirmed = window.confirm("Confirmation de la suppression");
      if (confirmed) {
        try {
          setLoading(true);
          const docRef = doc(db, "workinghours", employeeID);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const updatedData = { ...docSnap.data() };
            const newArray = [...updatedData.dataArray];
            var lists = newArray.filter(x => {
              return x.id != index;
            })
            await updateDoc(docRef, {
              dataArray: lists,
            });
            getData();
          } else {
            console.log("No such document exists.");
          }
          setLoading(false);
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      console.log("id null");
    }
  };

  const editHandl = (itemId) => {
    // console.log(`empid ${id} work id ${itemId}`);
    if (itemId !== null) {
        // Find the index of the item with the given id
        // const index = workHistory.findIndex(item => item.id === itemId);
        const value = workHistory.find(item => item.id === itemId);
        // console.log(value)
        if (value !== null) {
            setEditId(value.id);
            const formattedDate = dayjs(value.date, "DD/MM/YYYY").format(
                "YYYY-MM-DDTHH:mm"
            );
            const todayDate = dayjs().format("YYYY-MM-DD"); // Format today's date
            setDate(dayjs(formattedDate));
            setStartTime(dayjs(`${todayDate}T${value.startTime}`));
            setEndTime(dayjs(`${todayDate}T${value.endTime}`));
            setNote(value.note);
            setTimeRange(value.totalHours);
        } else {
            alert("Item not found");
        }
    } else {
      alert("id null");
    }
};

  const editCancel = () => {
    setEditId(null);
    setDate(null);
    setStartTime(null);
    setEndTime(null);
    setNote("");
    setTimeRange(0);
  };

  // const editFunctions = async () => {
  //   if (editId !== null) {
  //     try {
  //       setAddLoading(true);
  //       const docRef = doc(db, "workinghours", id);
  //       const docSnap = await getDoc(docRef);
  //       if (docSnap.exists()) {
  //         const data = {
  //           date: moment(new Date(date)).format("DD/MM/YYYY"),
  //           startTime: moment(new Date(startTime)).format("HH:mm"),
  //           endTime: moment(new Date(endTime)).format("HH:mm"),
  //           totalHours: timeRange,
  //           note,
  //           month: moment(new Date(date)).format("MM"),
  //         };
  //         const updatedData = { ...docSnap.data() };
  //         if (updatedData.dataArray && updatedData.dataArray.length > editId) {
  //           updatedData.dataArray[editId] = {
  //             ...updatedData.dataArray[editId],
  //             date: moment(new Date(date)).format("DD/MM/YYYY"),
  //             startTime: moment(new Date(startTime)).format("HH:mm"),
  //             endTime: moment(new Date(endTime)).format("HH:mm"),
  //             totalHours: timeRange,
  //             note,
  //             month: moment(new Date(date)).format("MM"),
  //           };
  //         } else {
  //           console.log("Array index out of range or array is empty.");
  //           return;
  //         }
  //         await updateDoc(docRef, updatedData);
  //         setEditId(null);
  //         setDate(null);
  //         setStartTime(null);
  //         setEndTime(null);
  //         setTimeRange(0);
  //         setNote("");
  //         getData();
  //       } else {
  //         console.log("No such document exists.");
  //       }
  //       setAddLoading(false);
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   } else {
  //     console.log("id null");
  //   }
  // };

  const editFunction = async () => { // Accept editId as a parameter
    if (editId !== null) {
        try {
            setAddLoading(true);
            const docRef = doc(db, "workinghours", id);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                const updatedData = { ...docSnap.data() };
                // Find the index of the item with the given editId
                const editedItemIndex = updatedData.dataArray.findIndex(item => item.id === editId);
                if (editedItemIndex !== null) {
                    // Update the item with the new data
                    updatedData.dataArray[editedItemIndex] = {
                        date: moment(new Date(date)).format("DD/MM/YYYY"),
                        startTime: moment(new Date(startTime)).format("HH:mm"),
                        endTime: moment(new Date(endTime)).format("HH:mm"),
                        totalHours: timeRange,
                        note,
                        month: moment(new Date(date)).format("MM"),
                        id: editId // Retain the original id
                    };
                    await updateDoc(docRef, updatedData);
                    // Reset state variables and fetch updated data
                    setEditId(null);
                    setDate(null);
                    setStartTime(null);
                    setEndTime(null);
                    setTimeRange(0);
                    setNote("");
                    getData();
                } else {
                    console.log("Item not found in dataArray.");
                }
            } else {
                console.log("No such document exists.");
            }
            setAddLoading(false);
        } catch (error) {
            console.log(error);
        }
    } else {
        console.log("editId is null");
    }
};

  return (
    <div className="container">
      <Paper elevation={3} style={{ marginTop: 10 }}>
        {loading && <LinearProgress />}
        <div className="row" style={{ padding: 10 }}>
          <div
            className="col-5"
            style={{ display: "flex", alignItems: "center" }}
          >
            <ArrowBackIcon
              size={20}
              color="primary"
              onClick={() => navigate("/employees")}
            />
            {/* <button
              type="button"
              class="btn btn-primary"
              onClick={() => navigate("/employees")}
            >
              <MdArrowBackIosNew size={25} />
            </button> */}
            <div className="profile-card">
              <div className="profile-image">
                <img
                  src={profileImage ? profileImage : "/workericon.png"}
                  alt="profile"
                />
              </div>
              <div className="profile-name">
                <h4>{`${nom} ${prenom}`}</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="details-body">
            <ul
              class="nav nav-pills nav-fill"
              style={{
                padding: 5,
                borderTop: "2px solid #1976d261",
                borderBottom: "2px solid rgb(25 118 210 / 18%)",
              }}
            >
              {tabs.map((tab, index) => (
                <li key={index} class="nav-item">
                  <p
                    style={{ margin: 0 }}
                    className={
                      tab.label === selectedTab
                        ? "nav-link tab-menu active"
                        : "nav-link tab-menu"
                    }
                    onClick={() => setSelectedTab(tab.label)}
                  >
                    {tab.label}
                  </p>
                </li>
              ))}
            </ul>
            <div className="tab-content">
              {selectedTab === "Details" && (
                <>
                  <div className="row">
                    <div>
                      <div class="card-body detailsData">
                        <div className="row">
                          <div className="col-6">
                            <div className="form-group">
                              <div class="row">
                                <label class="col-sm-4">Nom:</label>
                                <div class="col-sm-8">
                                  <p>{nom}</p>
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <div class="row">
                                <label class="col-sm-4">Prenom:</label>
                                <div class="col-sm-8">
                                  <p>{prenom}</p>
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <div class="row">
                                <label class="col-sm-4">
                                  N° Sécurité Social:
                                </label>
                                <div class="col-sm-8">
                                  <p>{ssn}</p>
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <div class="row">
                                <label class="col-sm-4">Adresse:</label>
                                <div class="col-sm-8">
                                  <p>{address}</p>
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <div class="row">
                                <label class="col-sm-4">Né(e) Le: </label>
                                <div class="col-sm-8">
                                  <p>{dob}</p>
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <div class="row">
                                <label class="col-sm-4">Pays:</label>
                                <div class="col-sm-8">
                                  <p>{pays}</p>
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <div class="row">
                                <label class="col-sm-4">Mail:</label>
                                <div class="col-sm-8">
                                  <p>{mail}</p>
                                </div>
                              </div>
                            </div>
                            <div className="form-group">
                              <div class="row">
                                <label class="col-sm-4">Tel:</label>
                                <div class="col-sm-8">
                                  <p>{tel}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="detail-action">
                          <div className="row">
                            <div className="col-12">
                              <button
                                type="button"
                                class="btn btn-primary"
                                onClick={() => navigate("/editemployee")}
                                style={{ marginRight: "10px" }}
                              >
                                Modifier
                              </button>
                              <button
                                onClick={handleDeleteUser}
                                type="button"
                                class="btn btn-danger"
                              >
                                Supprimer
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {selectedTab === "Works" && (
                <div className="row">
                  <div className="col-12">
                    <div style={{ margin: 0 }}>
                      <div class="card-body">
                        <h5 class="card-title">
                          Ajouter des heures de travail
                        </h5>
                        <div className="row">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Grid container spacing={2}>
                              <Grid item xs={6} md={3}>
                                <div className="form-group">
                                  <DatePicker
                                    style={{ height: "300px" }}
                                    value={date}
                                    onChange={(e) => setDate(e)}
                                    format="DD/MM/YYYY"
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={6} md={3}>
                                <div className="form-group">
                                  <TimeField
                                    label="Heure de début"
                                    ampm={false}
                                    value={startTime}
                                    onChange={(newValue) =>
                                      handleStartTimeChange(newValue)
                                    }
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={6} md={3}>
                                <div className="form-group">
                                  <TimeField
                                    label="Heure de fin"
                                    ampm={false}
                                    value={endTime}
                                    onChange={(newValue) =>
                                      handleEndTimeChange(newValue)
                                    }
                                  />
                                </div>
                              </Grid>
                              <Grid item xs={6} md={3}>
                                <div className="form-group">
                                  <TextField
                                    label="Note"
                                    value={note}
                                    onChange={(e) => setNote(e.target.value)}
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          </LocalizationProvider>
                          <Divider />
                          <Grid
                            container
                            spacing={1}
                            justifyContent="flex-end"
                            sx={{ flexGrow: 1 }}
                          >
                            <Grid item xs={6} md={3}>
                              <div className="form-group">
                                {editId !== null ? (
                                  <Box sx={{ display: "flex", gap: 1 }}>
                                    <IconButton
                                      color="error"
                                      onClick={editCancel}
                                    >
                                      <CancelIcon size={15} color={"error"} />
                                    </IconButton>
                                    <Button
                                      type="button"
                                      class="btn btn-primary"
                                      onClick={editFunction}
                                      loading={addLoading}
                                      sx={{ fontWeight: 600, height: "55px" }}
                                    >
                                      Mise à jour
                                    </Button>
                                  </Box>
                                ) : (
                                  <Button
                                    style={{ height: "54px", width: "100%" }}
                                    onClick={handleConfirm}
                                    loading={addLoading}
                                    color="success"
                                  >
                                    Ajouter
                                  </Button>
                                )}
                              </div>
                            </Grid>
                          </Grid>
                          <div>
                            <p> Heures totales: {timeRange}</p>
                          </div>
                        </div>

                        <div className="row">
                          <table class="table table-striped workEntry">
                            <thead>
                              <tr>
                                <th scope="col">Date</th>
                                <th scope="col">Entrée/Sortie</th>
                                <th scope="col">Totale</th>
                                <th scope="col">Note</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {workHistory.map((item, index) => (
                                <tr key={index}>
                                  <td>{item.date}</td>
                                  <td>
                                    {item.startTime}-{item.endTime}
                                  </td>
                                  <td>{item.totalHours}</td>
                                  <td>{item.note}</td>
                                  <td>
                                    <IconButton
                                      color="error"
                                      onClick={() => editHandl(item.id)}
                                      aria-label="delete"
                                    >
                                      <EditIcon size={15} color={"success"} />
                                    </IconButton>
                                    <IconButton
                                      color="error"
                                      onClick={() => deleteWorkHour(item.id)}
                                      aria-label="delete"
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </td>
                                </tr>
                              ))}
                              <tr>
                                <th scope="row" style={{ color: "red" }}>
                                  Total
                                </th>
                                <td></td>
                                <td style={{ color: "red" }}>
                                  <b>{totalWorkingHours}</b>
                                </td>
                                <td></td>
                                <td></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {selectedTab === "Payment" && <Payment id={id} />}
              {selectedTab === "Notes" && <Notes id={id} />}
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
}

export default EmployeeDetails;

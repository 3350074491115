import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./Firebase";
import Home from "./pages/Home";
import Nav from "./Components/Nav";
import Employees from "./pages/employees/Employees";
import AddEmployee from "./pages/employees/AddEmployee";
import { ToastContainer } from "react-toastify";
import EmployeeDetails from "./pages/employees/EmployeeDetails";
import Chantiers from "./pages/chantier/Chantiers";
import AddChantier from "./pages/chantier/AddChantier";
import ChantierDetails from "./pages/chantier/ChantierDetails";
import Settings from "./pages/settings/Settings";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import EditEmployee from "./pages/employees/EditEmployee";
import EditChantier from "./pages/chantier/EditChantier";
import { useDispatch, useSelector } from "react-redux";

import Persons from "./pages/personnelle/Persons";
import AddPerson from "./pages/personnelle/AddPerson";

import { setlogin } from "./stores/reducers/loginSlice";
import PersonDetails from "./pages/personnelle/PersonDetails";

function App() {
  const { loggedIn } = useSelector((state) => state.login.login);
  const dispatch = useDispatch();


  useEffect(() => {
    // Set persistence to "LOCAL" before setting up the auth state listener
    auth
      .setPersistence("LOCAL")
      .then(() => {
        // Proceed with setting up the auth state listener
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
            dispatch(setlogin({ loggedIn: true }));
          } else {
            dispatch(setlogin({ loggedIn: false }));
          }
        });
        return unsubscribe; // Cleanup function
      })
      .catch((error) => {
        // Handle any errors that occur while setting persistence
        console.error("Error setting persistence:", error);
      });
  }, [dispatch]); // Add dispatch to the dependency array

  return (
    <>
      {loggedIn === false ? (
        <Login />
      ) : (
        <>
          <div>
            <Nav />
            <Routes>
              <Route path="/" element={<Employees />} />
              <Route path="/employees" element={<Employees />} />
              <Route path="/dashboard" element={<Home />} />
              <Route path="/addemployee" element={<AddEmployee />} />
              <Route path="/editemployee" element={<EditEmployee />} />
              <Route path="/employeedetails" element={<EmployeeDetails />} />
              <Route path="/chantier" element={<Chantiers />} />
              <Route path="/addchantier" element={<AddChantier />} />
              <Route path="/chantierdetails" element={<ChantierDetails />} />
              <Route path="/editchantier" element={<EditChantier />} />
              <Route path="/persons" element={<Persons />} />
              <Route path="/addperson" element={<AddPerson />} />
              <Route path="/persondetails" element={<PersonDetails />} />
              <Route path="/settings" element={<Settings />} />
            </Routes>
            <ToastContainer />
          </div>
        </>
      )}
    </>
  );

  // }
}

export default App;
